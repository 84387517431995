
import { defineComponent, ref, computed, onMounted } from 'vue'
import DashboardSection from '@/components/atom/DashboardSection.vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import SmallButton from '@/components/atom/SmallButton.vue'
import Tile from '@/components/atom/Tile.vue'
import { useStore } from '@/store'
import { useRoute } from 'vue-router'
import authApi from '@/api/Authenticated'
import moment from 'moment'
import commonString from '@/utils/commonStrings'
import commonFunctions from '@/utils/commonFunctions'
export default defineComponent({
  components: {
    DashboardSection,
    CustomButton,
    Tile,
    SmallButton
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const docs = ref()
    const projectId = +route.params.id

    const userName = computed(() =>
      store.state.auth.tokenContent ? store.state.auth.tokenContent.studioName : 'User name'
    )
    function filterDate(date: Date) {
      return moment(date).format('DD/MM/YY')
    }
    async function getDocuments() {
      const res = await authApi.getProjectDocuments(projectId)
      docs.value = res
      commonFunctions.getRequiredDocuments(docs.value, 'PO', 'SOW', null)
    }
    onMounted(getDocuments)
    async function deleteDoc(projectId: number, documentId: number) {
      await authApi.deleteProjectDocument(projectId, documentId).then(function () {
        const docIndex = docs.value.documents.findIndex((e: any) => e.documentId === documentId)
        if (docIndex !== -1) {
          docs.value.documents.splice(docIndex, 1)
        }
        getDocuments()
      })
    }
    async function downloadDoc(projectId: number, documentId: number) {
      if (documentId != null) {
        const res = await authApi.downloadProjectDocument(projectId, documentId)
        if (res) {
          window.open(res.link, '_blank')
        }
      }
    }
    return { userName, projectId, filterDate, docs, deleteDoc, commonString, downloadDoc }
  }
})
