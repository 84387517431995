<template>
  <div class="project-doc">
    <DashboardSection>
      <template #top-right-button>
        <CustomButton
          @onClick="$router.push({ name: 'AddProjectDocument', params: { id: projectId } })"
          iconClass="plus"
          label="New document"
          color="grey"
          size="small"
          testId="addDocument"
        />
        <CustomButton
          @onClick="$router.push({ name: 'SowPdf', params: { projectId: projectId } })"
          iconClass="plus-white"
          label="Generate statement of work"
          color="blue"
          size="small"
          testId="generateSow"
        />
      </template>
      <template #content>
        <div class="docs-row" v-if="docs && docs.documents.length > 0">
          <Tile v-for="(item, i) in docs.documents" :key="i">
            <template #imageSection>
              <img src="@/assets/icons/document.svg" />
              <img src="@/assets/icons/error.svg" class="error-icon" v-if="item.documentId === null" />
              <img src="@/assets/icons/tick-green.svg" class="error-icon" v-if="item.documentId !== null" />
            </template>
            <template #title>
              <div class="title-row">
                <p v-if="item.documentId === null">Subcontractor Name</p>
                <p v-if="item.documentId !== null">Client Name</p>
              </div>
            </template>
            <template #right-section v-if="item.documentId === null">
              <SmallButton
                label="Add"
                @onClick="
                  $router.push({ name: 'AddProjectDocument', params: { id: projectId }, query: { type: item.type } })
                "
              ></SmallButton>
            </template>
            <template #desc>
              <div class="name-row">
                <span v-if="item.type === 'PO'">{{ commonString.PO }}</span>
                <span v-if="item.type === 'SOW'">{{ commonString.SOW }}</span>
              </div>
            </template>
            <template #footer v-if="item.documentId !== null">
              <div class="footer-row">
                <span>{{ userName }}</span>
                <span>{{ filterDate(item.createdAt) }}</span>
              </div>
            </template>
            <template #menu v-if="item.documentId !== null">
              <ul>
                <li @click="deleteDoc(projectId, item.documentId)">Remove</li>
                <li @click="downloadDoc(projectId, item.documentId)">Download</li>
              </ul>
            </template>
          </Tile>
        </div>
      </template>
    </DashboardSection>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, computed, onMounted } from 'vue'
import DashboardSection from '@/components/atom/DashboardSection.vue'
import CustomButton from '@/components/atom/CustomButton.vue'
import SmallButton from '@/components/atom/SmallButton.vue'
import Tile from '@/components/atom/Tile.vue'
import { useStore } from '@/store'
import { useRoute } from 'vue-router'
import authApi from '@/api/Authenticated'
import moment from 'moment'
import commonString from '@/utils/commonStrings'
import commonFunctions from '@/utils/commonFunctions'
export default defineComponent({
  components: {
    DashboardSection,
    CustomButton,
    Tile,
    SmallButton
  },
  setup() {
    const store = useStore()
    const route = useRoute()
    const docs = ref()
    const projectId = +route.params.id

    const userName = computed(() =>
      store.state.auth.tokenContent ? store.state.auth.tokenContent.studioName : 'User name'
    )
    function filterDate(date: Date) {
      return moment(date).format('DD/MM/YY')
    }
    async function getDocuments() {
      const res = await authApi.getProjectDocuments(projectId)
      docs.value = res
      commonFunctions.getRequiredDocuments(docs.value, 'PO', 'SOW', null)
    }
    onMounted(getDocuments)
    async function deleteDoc(projectId: number, documentId: number) {
      await authApi.deleteProjectDocument(projectId, documentId).then(function () {
        const docIndex = docs.value.documents.findIndex((e: any) => e.documentId === documentId)
        if (docIndex !== -1) {
          docs.value.documents.splice(docIndex, 1)
        }
        getDocuments()
      })
    }
    async function downloadDoc(projectId: number, documentId: number) {
      if (documentId != null) {
        const res = await authApi.downloadProjectDocument(projectId, documentId)
        if (res) {
          window.open(res.link, '_blank')
        }
      }
    }
    return { userName, projectId, filterDate, docs, deleteDoc, commonString, downloadDoc }
  }
})
</script>
<style scoped lang="sass">
.name-row
  display: flex
  justify-content: space-between
  align-items: center
  font-size: 16px
.footer-row
  display: flex
  justify-content: space-between
  margin-top: 6px
.name-row span:nth-child(2)
  font-size: 12px
.docs-row
  display: flex
  flex-wrap: wrap
  & > *
    margin-right: 1rem
    margin-bottom: 1rem
.title-row p
  font-size: 12px
.green-tick
  padding: 4px 9px
.statement-work
  max-width: 100%
  margin: 0px 14px
</style>
<style lang="sass">
.project-doc .top-right-button
  @media( max-width: $md-breakpoint)
    justify-content: space-around
</style>
